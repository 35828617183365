import { Component, OnInit } from '@angular/core';

import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  files: FileList;
  fileName: string;
  year = (new Date()).getFullYear();

  constructor(private data: DataService) { }

  ngOnInit() { }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.files = event.target.files;
      this.fileName = this.files[0].name;
    }
  }

  onFormSubmit() {
    this.data.sendContactForm(this.files);
  }
  

}
