import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterModule, Routes, CanActivate } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';

// import { Carousel } from './shared/components/carousel/carousel';
// import { CarouselSlide } from './shared/components/carousel/carousel-slide';

import { AppComponent } from './app.component';

import { HeaderComponent } from './shared/components/header/header.component';
import { MenuComponent } from './shared/components/menu/menu.component';
import { TopBlockComponent } from './shared/components/top-block/top-block.component';

import { FooterComponent } from './shared/components/footer/footer.component';
import { OverlayComponent } from './shared/components/overlay/overlay.component';

// import { TimelineComponent } from './shared/components/timeline/timeline.component';
import { HomePageComponent } from './home-page/home-page.component';
import { OurexpertiseComponent } from './home-page/ourexpertise/ourexpertise.component';
import { WorksComponent } from './home-page/works/works.component';
import { TeamComponent } from './home-page/team/team.component';
import { ClientsComponent } from './home-page/clients/clients.component';
import { AboutPageComponent } from './about-page/about-page.component';

import { CareersPageComponent } from './careers-page/careers-page.component';
import { StartProjectPageComponent } from './start-project-page/start-project-page.component';

import { ProjectsPageComponent } from './projects-page/projects-page.component';
import { ProjectPlatinComponent } from './project-page.platin/project-page.component';
import { ProjectSyrexComponent } from './project-page.syrex/project-page.component';


import { DataService } from './shared/services/data.service';

@Injectable()
export class GuardService implements CanActivate {

  url: string;

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.url = route.routeConfig.path + '_';
        resolve(true);
      }, (this.url) ? 600 : 0);
    });
  }
}


const appRoutes: Routes = [
  { path: '', component: HomePageComponent, data: { state: 'home' }, canActivate: [GuardService] },
  { path: 'about', component: AboutPageComponent, data: { state: 'about' }, canActivate: [GuardService] },
  {
    path: 'projects', component: ProjectsPageComponent, canActivate: [GuardService], children: [
      { path: 'platin', component: ProjectPlatinComponent, canActivate: [GuardService] },
      { path: 'syrex', component: ProjectSyrexComponent, canActivate: [GuardService] }
    ]
  },
  { path: 'careers', component: CareersPageComponent, canActivate: [GuardService], data: { state: 'careers' } },
  { path: 'start', component: StartProjectPageComponent, canActivate: [GuardService], data: { state: 'start' } },
  { path: '**', component: HomePageComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    HeaderComponent,
    MenuComponent,
    TopBlockComponent,
    FooterComponent,
    OverlayComponent,
    OurexpertiseComponent,
    WorksComponent,
    TeamComponent,
    ClientsComponent,
    AboutPageComponent,
    ProjectsPageComponent,
    CareersPageComponent,
    StartProjectPageComponent,
    ProjectPlatinComponent,
    ProjectSyrexComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'landing' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [DataService, GuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
