import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { DataService } from '../shared/services/data.service';
import { CareerForm } from '../shared/models/models';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-careers-page',
  templateUrl: './careers-page.component.html',
  styleUrls: ['./careers-page.component.scss']
})
export class CareersPageComponent implements OnInit {

  files: FileList;
  fileName: string;
  model: CareerForm = {};
  careerForm: FormGroup;
  loading: boolean;

  constructor(private http: HttpClient, public data: DataService) {
    this.careerForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      url: new FormControl('', []),
      covering: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() { }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.files = event.target.files;
      this.fileName = this.files[0].name;
    }
  }

  onSubmit() {
    const value = this.careerForm.value;
    const form = new FormData();

    this.loading = true;

    form.append('_from', value.email);
    form.append('_subject', 'careerForm');
    form.append('name', value.name);
    form.append('phone', value.phone);
    form.append('url', value.url);
    form.append('covering letter', value.covering);

    if (this.files)
      form.append('_attachment', this.files[0]);

    this.http.post(environment.formApiUrlCareers, form, { responseType: 'text' })
      .subscribe(next => {
        this.loading = false;
        this.careerForm.reset(this.careerForm.value);
        this.onAlert('Thank you!');
      }, error => {
        let err = { message: 'Something wrong' };
        try { 
          err = JSON.parse(error.error); 
        } catch (e) { }
        this.onAlert('Error: ' + err.message);
        this.loading = false;
      });
  }

  onAlert(text: string) {
    alert(text);
  }

}
