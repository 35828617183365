import { Component, OnInit } from '@angular/core';

import { DataService } from '../../shared/services/data.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  constructor(public data: DataService) { }

  ngOnInit() { }

}
