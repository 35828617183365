import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentUrl: string;
  projectId: number;

  height: string;
  pageYOffset: string;

  trottle = false;

  constructor(private router: Router, public data: DataService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
    this.height = window.innerHeight + 'px';
  }

  ngOnInit() {
    window.addEventListener('scroll', this.onScroll.bind(this), true);
  }

  onResize() {
    this.height = window.innerHeight + 'px';
  }

  onScroll() {
    this.data.navOffset = window.pageYOffset > window.innerHeight;
  }

  toggleMenu() {
    if (this.trottle) { return; }

    this.trottle = true;
    this.data.menuOpened = !this.data.menuOpened;

    setTimeout(() => { this.trottle = false; }, 1000);
  }

  onClickLetsTalk() {
    this.data.showLetsTalkModal();
  }
}
