import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'landing';
  state = '';

  activeCover = false;
  temp = false;
  url: string;

  constructor(private router: Router) {
    this.calcVisible();
    window.onscroll = () => {
      this.calcVisible();
    };
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (this.url) {
          this.activeCover = true;
          this.temp = true;
        }
        this.url = val.url;
      }
      if (val instanceof NavigationEnd) {
        setTimeout(() => {
          this.activeCover = false;
        }, 700);
        setTimeout(() => {
          this.temp = false;
        }, 1500);
      }
    });
  }

  ngOnInit() {
    this.calcVisible();
  }

  calcVisible() {
    const frameZones = Array.from(document.querySelectorAll('.Block'));
    frameZones.forEach(el => {
      if (this.elementInViewport(el)) {
        el.classList.remove('invisible');
        el.classList.add('visible');
      } else {
        el.classList.add('invisible');
        el.classList.remove('visible');
      }
    });
  }

  elementInViewport(el) {
    let top = el.offsetTop;
    const height = el.offsetHeight;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
    }

    return (
      top < (window.pageYOffset + window.innerHeight) &&
      (top + height) > window.pageYOffset
    );
  }
}



