
export enum Overlay { none, Success, Error, LetsTalk }

export interface TimeLine {
  date: string;
  text: string;
}

export interface Client {
  logo: string;
  name: string;
  person: string;
  em: string;
  active?: boolean;
}

export interface Teammate {
  url: string;
  caption: string;
  position1: string;
  position2: string;
}

export interface Member {
  url: string;
  name: string;
  position: string;
}

export interface Project {
  title?: string;
  url: string;
  caption: string;
  description?: string;
  info?: string;
  result?: string;
  img1?: string;
  img2?: string;
  slides?: Array<string>;
  stack?: Array<any>;
  tasks?: Array<string>;
  team?: Array<Member>;
}

export interface Career {
  title: string;
  description: string;
  details: string[];
  active?: boolean;
}

export interface CareerForm {
  name?: string;
  email?: string;
  phone?: string;
  covering?: string;
  url?: string;
  files?: FileList;
}

export interface ProjectForm {
  name?: string;
  email?: string;
  phone?: string;
  company?: string;
  covering?: string;
  files?: FileList;
}