import { Component, OnInit } from '@angular/core';

import { DataService } from '../../services/data.service';
import { Overlay } from '../../models/models';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {

  files: FileList;
  fileName: string;

  constructor(public data: DataService) { }

  ngOnInit() {
    // this.data.overlay = Overlay.LetsTalk;
  }

  onClickCancel() {
    this.data.overlay = Overlay.none;
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.files = event.target.files;
      this.fileName = this.files[0].name;
    }
  }

  onFormSubmit() {
    this.data.sendContactForm(this.files);
  }
}