import { Component, OnInit, Input } from '@angular/core';

import { DataService } from '../../shared/services/data.service';


@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.scss'],
})
export class WorksComponent implements OnInit {

  @Input() modclass: string;
  @Input() hidemore: string;

  constructor(public data: DataService) { }

  ngOnInit() { }

}
