import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';

import { DataService } from '../shared/services/data.service';

@Component({
  selector: 'app-projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.scss']
})
export class ProjectsPageComponent implements OnInit {

  isProjectsPage: boolean;

  constructor(private router: Router, public data: DataService) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isProjectsPage = event.url.replace('/', '') === 'projects';
      }
    });
  }

  ngOnInit() { }

}
