import { Component, OnInit } from '@angular/core';

import { Client } from '../../shared/models/models';
import { DataService } from '../../shared/services/data.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  selected: Client;

  galleryOffset = 0;
  activeImage = 0;
  imageWidth = 0;

  constructor(public data: DataService) { }

  ngOnInit() {
    this.select(this.data.clients[0]);
  }

  select(client) {
    if (this.selected)
      this.selected.active = false;
    this.selected = client;
    this.selected.active = true;
  }

  onClick(next: number) {
    this.activeImage += next;
    this.galleryOffset = -100*this.activeImage / (this.data.clients.length-1);
  }

  private checkMobile() {
    return window.innerWidth < 576;
  }

}
