import { Component, OnInit } from '@angular/core';

import { DataService } from '../../services/data.service';

var initialized = false;

@Component({
  selector: 'app-top-block',
  templateUrl: './top-block.component.html',
  styleUrls: ['./top-block.component.scss']
})
export class TopBlockComponent implements OnInit {

  appHeaderLineTop: string;
  appHeaderLineBottom: string;
  windowHeight = window.innerHeight;

  constructor(public data: DataService) { }

  ngOnInit() {
    if (window.location.hostname !== 'localhost') {
      init();
      if (!initialized) {
        animate();
        initialized = true;
      }
    }

    setTimeout(() => {
      if (typeof window !== 'undefined') {
        window.addEventListener('scroll', this.onScroll.bind(this), true);
        window.addEventListener('resize', this.onScroll.bind(this), true);
        this.windowHeight = (document.querySelector('.TopBlock') as HTMLElement).offsetHeight;
      }
    });

    this.onScroll();
  }

  onClickLetsTalk() {
    this.data.showLetsTalkModal();
  }

  onScroll() {
    this.windowHeight = (document.querySelector('.TopBlock') as HTMLElement).offsetHeight;
    let maxHeigth = (200 < (window.innerHeight / 5)) ? 200 : (window.innerHeight / 5);
    maxHeigth = (window.innerWidth < 577) ? 60 : maxHeigth;
    const maxBottoHeigth = (window.innerWidth < 577) ? 20 : 40;
    this.appHeaderLineTop = ((maxHeigth - window.pageYOffset * 0.3 > 0) ? maxHeigth - window.pageYOffset * 0.3 : 0) + 'px';
    this.appHeaderLineBottom = ((window.pageYOffset * 0.3 < maxBottoHeigth) ? window.pageYOffset * 0.3 : maxBottoHeigth) + 'px';
  }
}

declare var THREE;

var SEPARATION = 100, AMOUNTX = 50, AMOUNTY = 50;
var container, stats;
var camera, scene, renderer;
var particles, count = 0;
var mouseX = 85, mouseY = -342;
var windowHalfX = window.innerWidth / 2;
var windowHalfY = window.innerHeight / 2;

function init() {
  container = document.getElementById('treejs');
  // document.body.appendChild(container);
  camera = new THREE.PerspectiveCamera(120, window.innerWidth / window.innerHeight, 1, 10000);
  camera.position.z = 1000;
  scene = new THREE.Scene();
  //
  var numParticles = AMOUNTX * AMOUNTY;
  var positions = new Float32Array(numParticles * 3);
  var scales = new Float32Array(numParticles);
  var i = 0, j = 0;
  for (var ix = 0; ix < AMOUNTX; ix++) {
    for (var iy = 0; iy < AMOUNTY; iy++) {
      positions[i] = ix * SEPARATION - ((AMOUNTX * SEPARATION) / 2); // x
      positions[i + 1] = 0; // y
      positions[i + 2] = iy * SEPARATION - ((AMOUNTY * SEPARATION) / 2); // z
      scales[j] = 1;
      i += 3;
      j++;
    }
  }
  var geometry = new THREE.BufferGeometry();
  geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3));
  geometry.addAttribute('scale', new THREE.BufferAttribute(scales, 1));
  var material = new THREE.ShaderMaterial({
    uniforms: {
      color: { value: new THREE.Color(0xffffff) },
    },
    vertexShader: document.getElementById('vertexshader').textContent,
    fragmentShader: document.getElementById('fragmentshader').textContent
  });
  //
  particles = new THREE.Points(geometry, material);
  scene.add(particles);
  //
  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  container.appendChild(renderer.domElement);
  // stats = new Stats();
  // container.appendChild(stats.dom);
  document.addEventListener('mousemove', onDocumentMouseMove, false);
  document.addEventListener('touchstart', onDocumentTouchStart, false);
  document.addEventListener('touchmove', onDocumentTouchMove, false);
  //
  window.addEventListener('resize', onWindowResize, false);
}
function onWindowResize() {
  windowHalfX = window.innerWidth / 2;
  windowHalfY = window.innerHeight / 2;
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}
//
function onDocumentMouseMove(event) {
  mouseX = event.clientX - windowHalfX;
  mouseY = event.clientY - windowHalfY;
}
function onDocumentTouchStart(event) {
  if (event.touches.length === 1) {
    event.preventDefault();
    mouseX = event.touches[0].pageX - windowHalfX;
    mouseY = event.touches[0].pageY - windowHalfY;
  }
}
function onDocumentTouchMove(event) {
  if (event.touches.length === 1) {
    event.preventDefault();
    mouseX = event.touches[0].pageX - windowHalfX;
    mouseY = event.touches[0].pageY - windowHalfY;
  }
}
//
function animate() {
  requestAnimationFrame(animate);
  render();
  // stats.update();
}
function render() {
  camera.position.x = 0;
  camera.position.y = 200;
  camera.lookAt(scene.position);
  var positions = particles.geometry.attributes.position.array;
  var scales = particles.geometry.attributes.scale.array;
  var i = 0, j = 0;
  for (var ix = 0; ix < AMOUNTX; ix++) {
    for (var iy = 0; iy < AMOUNTY; iy++) {
      positions[i + 1] = (Math.sin((ix + count) * 0.3) * 50) +
        (Math.sin((iy + count) * 0.5) * 50);
      scales[j] = (Math.sin((ix + count) * 0.3) + 1) * 8 +
        (Math.sin((iy + count) * 0.5) + 1) * 8;
      i += 3;
      j++;
    }
  }
  particles.geometry.attributes.position.needsUpdate = true;
  particles.geometry.attributes.scale.needsUpdate = true;
  renderer.render(scene, camera);
  count += 0.1;
}