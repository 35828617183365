import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Teammate, Project, Career, Client, Overlay } from '../models/models';
import { environment } from '../../../environments/environment';

@Injectable()
export class DataService {

  menuOpened = false;
  navOffset = false;

  projects: Project[] = environment.projects;
  careers: Career[] = environment.careers;
  clients: Client[] = environment.clients;
  team: Teammate[] = environment.team;

  overlay: Overlay;
  errorText: string;

  contactForm: FormGroup;
  careerForm: FormGroup;

  loading: boolean;

  constructor(private http: HttpClient) {
    this.contactForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      company: new FormControl('', []),
      covering: new FormControl('', [Validators.required]),
      accept_personal: new FormControl('', [Validators.required]),
      accept_contact: new FormControl('', [Validators.required]),
    });
  }

  _contactForm() {

  }

  showSuccessModal() {
    this.overlay = Overlay.Success;
  }

  showErrorModal(text?: string) {
    this.overlay = Overlay.Error;
    this.errorText = text || 'Unknown error. Please try later.';
  }

  showLetsTalkModal() {
    this.overlay = Overlay.LetsTalk;
  }

  sendContactForm(files?: FileList) {
    const value = this.contactForm.value;
    const form = new FormData();

    this.loading = true;

    form.append('_from', value.email);
    form.append('_subject', 'contactForm');
    form.append('name', value.name);
    form.append('phone', value.phone);
    form.append('company', value.company);
    form.append('covering letter', value.covering);

    if (files) {
      form.append('_attachment', files[0]);
    }

    this.http.post(environment.formApiUrlProjects, form, { responseType: 'text' })
      .subscribe(next => {
        this.loading = false;
        this.contactForm.reset(this.contactForm.value);
        this.showSuccessModal();
      }, error => {
        let err = { message: 'Something wrong' };
        try {
          err = JSON.parse(error.error);
        } catch (e) { }
        this.showErrorModal('Error: ' + err.message);
        this.loading = false;
      });
  }

}
