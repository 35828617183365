import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutpage',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

  gallery = [
    'assets/content/team/7.png',
    'assets/content/team/6.png',
    'assets/content/team/5.png',
    'assets/content/team/3.png',
    'assets/content/team/2.png',
    'assets/content/team/1.png',
  ];

  galleryOffset = 0;
  activeImage = 0;
  imageWidth = 0;
  innerWidth = window.innerWidth;
  isMobile = this.checkMobile();
  imageShowPercent = this.calculatePercent(this.isMobile);

  constructor() { }

  ngOnInit() { 
    this.imageWidth =  this.imageShowPercent / this.gallery.length;
  }

  onResize() {
    this.innerWidth = window.innerWidth;
    this.isMobile = this.checkMobile();
    this.imageShowPercent = this.calculatePercent(this.isMobile);
    this.imageWidth =  this.imageShowPercent / this.gallery.length;
  }

  onClick(next: number) {
    this.activeImage += next;
    this.galleryOffset = -this.imageShowPercent * this.activeImage / this.gallery.length;
    
    console.log(next, this.galleryOffset);
  }

  private calculatePercent(isMobile: boolean) {
    return isMobile ? 90 : 50
  }

  private checkMobile() {
    return window.innerWidth < 576;
  }

}
