// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  formApiUrlCareers: 'https://send-mail.vareger.com/hr@vareger.com',
  formApiUrlProjects: 'https://send-mail.vareger.com/projects@vareger.com',
  formConfirmUrl: 'https://mailthis.to/confirm',
  projects: [{
    title: 'Platin',
    route: true,
    meta1: 'Proof of Location Protocol on the Blockchain',
    meta2: '',
    tags: ['ZK', 'Blockchain'],
    person: 'Allon Mason',
    personPosition: 'Platin.io Co-Founder & CEO',
    em: 'Platin’s continued work and enduring relationship with Vareger is a testament to the uncompromising quality and skill of the Vareger team. Our continued collaboration and mutual synergy has allowed us to grow rapidly and meet our technical team’s most challenging goals.',
    url: '../../assets/content/1.png',
    caption: 'PLATIN: Secure Proof of Location (PoL) protocol',
    description: 'Platin is the world’s leading decentralized Proof of Location Protocol and marketplace. On the one hand, it is a set of services that enables location-based operations for crypto assets. On the other hand, it is a mobile app and incentivization program, that motivates users to seek and gather radio signal data in exchange for crypto tokens.  Since the location data are very sensitive, the Platin’s team had to choose the best way to preserve the privacy of its users i.e to create a highly secure, verifiable, decentralized and lightweight solution. That is what Vareger was asked to do.',
    stack: [
      {
        text: 'Solidity based smart contracts',
        image: '../assets/content/projects/solidiy_logo_frame.svg'
      }, {
        text: 'Angular 4',
        image: '../assets/content/projects/angular_logo_frame.svg'
      }, {
        text: 'Java',
        image: '../assets/content/projects/java_logo_frame.svg'
      }, {
        text: 'Android, iOS',
        image: '../assets/content/projects/mobile_logo_frame.svg'
      }, {
        text: 'EOS, Ethereum',
        image: '../assets/content/projects/cryptos_logo_frame.svg'
      }
    ],
    timeline: [
      { date: 'Aug 2017', text: 'StaStart of cooperation with Platin, Consulting' },
      { date: 'Feb 2018', text: 'Vareger created Mobile POC with Amazon Alexa integration' },
      { date: 'May 2018', text: 'Vareger staffed and launched Cryptographical department for Platin' },
      { date: 'Jul 2018', text: 'Mobile Development' },
      { date: 'Aug 2018', text: 'SolidityGEO development' },
      { date: 'Oct 2018', text: 'Vareger Smart-contracts successfully passed audit from Chainsecurity (CH), DeepIt (CH)' },
      { date: 'Dec 2018', text: 'Vareger & Platin released signal harvesting app' },

    ],
    tasks: [
      'A mobile signal harvesting application that scans signals from a wide range of radio spectrum such as mobile and WiFi, Bluetooth, GPS data, and creates a unique profile. All that help to protect location data from falsifications.', 
      'A unique protocol based on the Zero Knowledge concept that proofs the user’s location without disclosing their real coordinates.',
      'An integration of both geolocation data and zero knowledge functionalities with Ethereum blockchain ecosystem. '
    ],
    result: 'Vareger created a secure and verifiable Proof of Location (PoL) blockchain-based protocol that provides clients with automated and certified high confidence PoL data. Vareger also created a mobile app that allows for radio signal data gathering with the reward paid in cryptocurrency. Both helped Platin to grow rapidly and become one of the leading decentralized marketplace solutions. ',
    info: 'Platin allows anyone to geo-locate a digital asset (cryptocurrencies, documents, images, etc.), anywhere on the map, anywhere in the world, in real time. This is made possible through open, privacy preserving zero knowledge proofs developed as part of the Platin protocol. With Platin, any digital asset can now have a fixed location in physical space. This incentivizes individuals, organizations and large groups of people to move and interact with digital assets - and each other - in brand new ways.',
    img1: '../assets/content/projects/platin/platin_hero_image2.png',
    img2: '../assets/content/projects/platin/platin_hero_image3.png',
    slides: [
      '../assets/content/projects/platin/platin_inner_content_1.png',
      '../assets/content/projects/platin/platin_inner_content_1.png',
      '../assets/content/projects/platin/platin_inner_content_1.png',
      '../assets/content/projects/platin/platin_inner_content_1.png',
      '../assets/content/projects/platin/platin_inner_content_1.png',
      '../assets/content/projects/platin/platin_inner_content_1.png'
    ],
    team: [{
      url: '../../assets/content/team/varege_1.png',
      name: 'Misha Nice',
      position: 'Blockchain Architect'
    }, {
      url: '../../assets/content/team/varege_1.png',
      name: 'Misha Nice',
      position: 'Blockchain Architect'
    }, {
      url: '../../assets/content/team/varege_1.png',
      name: 'Misha Nice',
      position: 'Blockchain Architect'
    }, {
      url: '../../assets/content/team/varege_1.png',
      name: 'Misha Nice',
      position: 'Blockchain Architect'
    }]
  }, {
    url: '../../assets/content/2.png',
    route: true,
    caption: 'Syrex: Paperless data management system',
    captionColor: '#4a4a4a',
    description: 'Syrex is an open-access software tool that is currently utilized in almost 20 countries of EECA, Middle East, South Asia, and Africa. It was created in 2004  by the Alliance for Public Health to monitor and track information about clients, services, and commodities provided within HIV prevention programs.  Vareger joined the project in 2014 to transform it into the fast response, a cost-effective and secure application, with respect to personal data. The scope of the project was to create a solution to automatically identify the client without revealing their personal information and to develop a user-friendly app that will help outreach workers collect data on clients reached and services provided.',

    tags: ['Healthteck', 'AI/ML'],
    title: 'Alliance for Public Health',
    meta1: 'Paperless data management system app',
  }, {
    url: '../../assets/content/999.png',
    caption: 'New stories coming soon',
    captionColor: '#7e7e7e',
    tags: [],
    route: false
  }],
  careers: [{
    title: 'BUSINESS DEVELOPMENT MANAGER',
    description: 'We’re hiring for the role of Sales and Business Development manager. If you have a passion for sales activities, then you might be what we’re looking for?',
    details: [
    ],
    requirements: [
      '1-2 years of experience in B2B sales activities (IT clients preferably)',
      'Advanced English',
      'Ability to work individually and achieve specific KPIs'
    ],
    responsibilities: [
      'Market research for strategy definition',
      'Lead generation',
      'Initial and further communication with prospects and leads',
      'Participation in full cycle sales process including deals closure',
      'High level communication with the Customers',
      'Coordination of estimation procedures on pre-sales stage',
      'Cooperation with the production, legal and recruitment departments'
    ],
    advantages: [
    ],
    project_description:''
  }, {
    title: 'BUSINESS ANALYST',
    description: 'We’re hiring for the role of Business Analyst for our Kyiv office. If you have a passion for agritech and France, then you might be what we’re looking for?',
    details: [
      'Working as proxy product owner for the Dev team(s)',
      'Working closely with a Product owner on the Customer  side in order to specify business/agritech requirements into detailed use case scenarios',
      'Create and maintain comprehensive project documentation (functional specification, technical specification)',
      'Meet with clients to take detailed ordering briefs and clarify specific requirements of the project',
      'Leading the product demo sessions',
      'Participation in presale activities together with Customer representatives',
      'Support AgTech domain competence center growth'
    ],
    requirements: [
      'Knowing a key principals of Agrotechnology, plant growing lifecycle',
      'Knowing precision agriculture technics',
      'Understanding of SW development lifecycle',
      'Experience with documenting algorithms, use case scenarios, etc',
      'English level - Advanced'
    ],
    responsibilities: [
      'Market research for strategy definition',
      'Lead generation',
      'Initial and further communication with prospects and leads',
      'Participation in full cycle sales process including deals closure',
      'High level communication with the Customers',
      'Coordination of estimation procedures on pre-sales stage',
      'Cooperation with the production, legal and recruitment departments'
    ],
    advantages: [
      'Past experience working on BA/SA position',
      'Experience in developing and documenting complex math algorithms',
      'Experience with Jira and Confluence tools',
      'Livestock and precision farming practical experience',
      'French language'
    ],
    project_description:'France-based company growing the portfolio with Data-driven decision support tool to support the industry in getting the maximum outcome from the farming data.'
  }],
  team: [{
    url: '../../assets/content/oleg.png',
    caption: 'Oleg Pun',
    position1: '../../assets/content/CEO_border.svg',
    position2: '../../assets/content/CEO_full.svg'
  }, {
    url: '../../assets/content/michail.png',
    caption: 'Mykhailo Tiutin',
    position1: '../../assets/content/CTO_border.svg',
    position2: '../../assets/content/CTO_full.svg'
  }, {
    url: '../../assets/content/dmitriy.png',
    caption: 'Dmitriy Sinyehin',
    position1: '../../assets/content/UXUI_border.svg',
    position2: '../../assets/content/UXUI_full.svg'
  }],
  clients: [  {
    logo: '../../assets/content/clients/syrex_logo_colored.svg',
    name: 'SyrEx',
    person: 'Pavlo Smyrnov',
    em: 'You can make rather sophisticated things to become so simple. Together guys, we can make the world better and healthier. Thanks Vareger, thanks for a great job'
  },
  {
    logo: '../../assets/content/clients/betex_colored.svg',
    name: 'Betex.io',
    person: 'Oleg Torkhov',
    em: 'Our cooperation began in 2017 when Betex was just an idea. Betex partnered with Vareger in effort of delivering brand new decentralized betting platform. Vareger team took active part shaping the idea, designing blockchain based architecture and implemented first MVP. Since that time Betex came through different stages, tested new ideas and took few pivots. But my experience working with guys was constantly good'
  },
  {
    logo: '../../assets/content/clients/intensifly_logo_color.svg',
    name: 'INTESIFLY',
    person: 'Roman Golubtsov',
    em: 'We\'ve been working with Vareger for three years. During that time, the company proved to be highly competent in building and managing a dedicated team of software developers who definitely knew their job! They provided us with lots of thoughtful advice on relevant issues. To crown that all, Vareger ensured the most friendly and comfortable working environment. It was the thing that helped us to face our challenging tasks'
  }, {
    logo: '../../assets/content/clients/platin_logo_colored.svg',
    name: 'Platin.io Co-Founder & CEO',
    person: 'Allon Mason',
    em: 'Platin’s continued work and enduring relationship with Vareger is a testament to the uncompromising quality and skill of the Vareger team. Our continued collaboration and mutual synergy has allowed us to grow rapidly and meet our technical team’s most challenging goals'
  }
]
};
