import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Project } from '../shared/models/models';
import { DataService } from '../shared/services/data.service';

@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  styleUrls: ['./project-page.component.scss']
})
export class ProjectPlatinComponent implements OnInit {

  id: number;
  project: Project;
  activeSlideId = 1;

  constructor(private route: ActivatedRoute, private router: Router, public data: DataService) {
    route.params.subscribe(params => {
      this.id = params.id;
    });
  }

  ngOnInit() {
    // if (this.data.projects[this.id])
    //   this.project = this.data.projects[this.id];
    // else
    //   this.router.navigate(['projects']);
  }

  selectSlide(slideId) {
    this.activeSlideId = slideId;
  }

  slidePos(slideId) {
    const offset = (slideId - this.activeSlideId) * 50;
    return `${offset}%`;
  }

  slideZ(slideId) {
    const zIndex = (slideId > this.activeSlideId) ? this.activeSlideId - slideId : slideId - this.activeSlideId;
    return zIndex + 100;
  }

}
