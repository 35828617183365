import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { DataService } from '../shared/services/data.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-start-project-page',
  templateUrl: './start-project-page.component.html',
  styleUrls: ['./start-project-page.component.scss']
})
export class StartProjectPageComponent implements OnInit {

  files: FileList;
  fileName: string;
  contactForm: FormGroup;
  loading: boolean;

  constructor(private http: HttpClient) {
    this.contactForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      company: new FormControl('', []),
      covering: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() { }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.files = event.target.files;
      this.fileName = this.files[0].name;
    }
  }

  onSubmit() {
    const value = this.contactForm.value;
    const form = new FormData();

    this.loading = true;

    form.append('_from', value.email);
    form.append('_subject', 'contactForm');
    form.append('name', value.name);
    form.append('phone', value.phone);
    form.append('company', value.company);
    form.append('covering letter', value.covering);

    if (this.files)
      form.append('_attachment', this.files[0]);

    this.http.post(environment.formApiUrlProjects, form, { responseType: 'text' })
      .subscribe(next => {
        this.loading = false;
        this.contactForm.reset(this.contactForm.value);
        this.onAlert('Thank you!');
      }, error => {
        console.log(error);
        let err = { message: 'Something wrong' };
        try { 
          err = JSON.parse(error.error); 
        } catch (e) { }
        this.onAlert('Error: ' + err.message);
        this.loading = false;
      });
  }

  onAlert(text: string) {
    alert(text);
  }

}
