import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ourexpertise',
  templateUrl: './ourexpertise.component.html',
  styleUrls: ['./ourexpertise.component.scss']
})
export class OurexpertiseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
